import React, { Fragment } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
  }
  render() {
      if (this.state.hasError) {
          return (
              <Fragment>
                  <Stack
                      justifyContent="center"
                      display="flex"
                      sx={{
                          textAlign: 'center',
                          minHeight: '100vh',
                      
                      }}
                  >
                      <Box >
                          <Typography component="h2" sx={{ color: '#ffffff90', mb: 2, fontSize: '20px' }}>Oops, something went wrong!</Typography>
                          <Button
                              type="button"
                              onClick={() => this.setState({ hasError: false })}
                              className="themeBtn"
                              sx={{ px: 5 }}
                          >
                              Try again?
                          </Button>
                      </Box>
                  </Stack>
              </Fragment>
          )
      }
    return this.props.children
  }
}

export default ErrorBoundary