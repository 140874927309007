import React, { useState, Fragment, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import 'react-toastify/dist/ReactToastify.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'js-cookie';
import { server } from '../../config';
import axios from 'axios';
import { parseCookies } from 'nookies'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { useRouter, asPath } from 'next/router';
import { nanoid } from 'nanoid'

import darkTheme from '../../src/theme';
// import CloseIcon from "../../public/images/icons/Close.svg";
// import Image from "next/image";


const signInContainer = {
  padding: 0,
  background: `rgba(0,0,0,0.75)`,
  '& h4': {
    fontSize: 20,
    fontWeight: 700,
    color: darkTheme.palette.common.white,
  },
  '& h6': {
    fontSize: 14,
    color: darkTheme.palette.grey[300],
    fontWeight: 500,
  },
};

const loginCardContent = {
  backgroundColor: darkTheme.palette.background.signInCard,
  opacity: 1,
  padding: darkTheme.spacing(3), 
  borderRadius: 3,   
  width: '45%',
  display: 'block',
  marginTop: darkTheme.spacing(10),
  padding: darkTheme.spacing(7),
  [darkTheme.breakpoints.down("md")]: {
    width: '75%',
    padding: darkTheme.spacing(5),
  },
  [darkTheme.breakpoints.down("sm")]: {
    width: '95%',
    padding: darkTheme.spacing(3),
  },
};

function CityModal(props) {
  const { open, onClose } = props; 
  const [cities, setCities] = useState([]); 
  const [loading, setLoading] = useState(true)
  const [ cityName, setCityName ] = useState(''); 
  const cookieCity = parseCookies();  
  const router = useRouter()

    useEffect(() => {
      axios.get('/api/cities/list', {
            headers: {
                'x-auth-token': process.env.JWT_TOKEN
            }
      }).then((response) => {
        if (response.status == 200) {
          setCities(response.data ? response.data.result : [])
          setLoading(false)
        } else {
          setLoading(false)
        }
      })      
      .catch(err => {
        console.log(err);
      })
      const city = cookieCity.city ? cookieCity.city : '';
      Cookies.set('city', city, { expiredIn: '5h', secure: true, sameSite : "none" }); 
      setCityName(city); 
    }, []);
    
    const setCity = (e) => {
        let cityName = e.target.value;        
        setCityName(cityName); 
        onClose();
      Cookies.set('city', cityName, { expiredIn: '5h', secure: true, sameSite: "none" });
      if (asPath) {
        const arr = asPath.split("/")
        const lastSegment = arr.pop()
        if (
          arr[1] == 'home' ||
          arr[1] == 'events' ||
          arr[1] == 'events' ||
          arr[1] == '' ||
          arr[1] == 'clubs' ||
          arr[1] == 'deals'
        ) {
          window.location.href = `${server}/${cityName}/${lastSegment}`;
        } else {
          window.location.href = `${server}/${cityName}/home`;
        }
      } else {
        window.location.reload()
      }
    }

    return (
        <Typography component="div">
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={signInContainer}
        >
          <DialogContent>
            <Box maxWidth={"xl"} component="div">

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="xl"                         
                >                  
                <Typography component="div" sx={loginCardContent} >
                                                 
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="lg"
                  sx={{
                    maxWidth:'unset !important'
                  }}
                >
                  <div>
                    <h1>Select Your City</h1>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      maxWidth="lg"
                      sx={{ mt: 4 }}
                  >  
                    {!loading ? (
                      <div className="loginCard" >
                        <Grid maxWidth="lg" display="block">
                          <RadioGroup row aria-label="city" name="city" defaultValue="hyderabad">
                            <Grid key={-1} item xs={12} sm={6} md={6} lg={4} xl={4} sx={{ mb: 3 }}>
                              <FormControlLabel
                                value={''}
                                control={
                                  cityName == '' ? (
                                    < Radio checked={true} sx={{ color: '#fff' }} />
                                  ) : (
                                    < Radio checked={false} sx={{ color: '#fff' }} />
                                  )
                                }
                                label={'All Cities'}
                                labelPlacement="end"
                                sx={{ color: '#ffffff90', fontSize: '20px', width:'100%' }}
                                onChange={setCity}
                              />
                            </Grid>
                              {cities.map((city, i) => (
                                <Grid key={nanoid(30)} item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ mb: 3 }}>
                                  <FormControlLabel
                                    value={city.name.toLowerCase()}
                                    control={
                                      cityName.toLowerCase() == city.name.toLowerCase() ? (
                                        < Radio checked={true} sx={{ color: '#fff' }} />
                                      ) : (
                                        < Radio checked={false} sx={{ color: '#fff' }} />
                                      )
                                    }
                                    label={city.name.toLowerCase()}
                                    labelPlacement="end"
                                    sx={{ color: '#ffffff90', fontSize: '20px', width:'100%', textTransform:'capitalize !important' }}
                                    onChange={setCity}
                                  />
                                </Grid>
                              ))}
                            </RadioGroup>
                        </Grid>
                      </div>
                    ) : (
                        <div className="loginCard">                         
                          <Stack sx={{ mt:5, mb:5, minWidth:'500px'}}  >                              
                              <Skeleton animation="wave" variant="body1" sx={{ mt: 2, color: 'grey.900' }} />
                              <Skeleton animation="wave" variant="body1" sx={{ mt: 2, color: 'grey.900' }} />
                              <Skeleton animation="wave" variant="body1" sx={{ mt: 2, color: 'grey.900' }} />
                          </Stack>                            
                      </div>
                    )}
                  </Stack>
                  <Stack direction="row" justifyContent="center" mt={3}>
                    <Button
                      className="themeBtnAlt"                            
                      variant="outline"
                      size="large"
                      onClick={onClose}                                
                    >Close
                    </Button>
                  </Stack>
                  </div>
                </Stack>
                </Typography>
              </Stack>
            </Box> 
          </DialogContent>
        </Dialog>
      </Typography>
    );
}
export default CityModal