import React, { useState, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginOTP from "./LoginOTP";
import axios from 'axios'
import darkTheme from '../../src/theme';
import CloseIconNew from "@mui/icons-material/Close";
import SignInImage from "../../public/images/SignInImage.png";
import Image from "next/image";
import { useRouter } from "next/router";

const signInContainer = {
  padding: 0,
  background: `rgba(0,0,0,0.75)`,
  '& h4': {
    fontSize: 30,
    fontWeight: 700,
    color: darkTheme.palette.primary.title,
    marginBottom: darkTheme.spacing(3),
    [darkTheme.breakpoints.down("sm")]: {
      marginTop: darkTheme.spacing(2),
      marginBottom: darkTheme.spacing(1),
      fontSize: 22,
      fontWeight: 500,
    },
  },
  '& h6': {
    fontSize: 16,
    color: darkTheme.palette.primary.location,
    fontWeight: 300,
    [darkTheme.breakpoints.down("sm")]: {
      marginBottom: darkTheme.spacing(1),
      fontSize: 14,
      fontWeight: 100,
    },
  },
};

const loginCardContent = {
  backgroundColor: darkTheme.palette.background.alternate,
  opacity: 1,
  border: `1px solid ${darkTheme.palette.background.ticket}`,  
  borderRadius: 3,   
  width: '55%',
  minHeight: '60vh',
  display: 'block',
  marginTop: darkTheme.spacing(10),
  padding: darkTheme.spacing(3),
  [darkTheme.breakpoints.down("md")]: {
    width: '75%',
    padding: darkTheme.spacing(5),
    marginTop: darkTheme.spacing(3),
  },
  [darkTheme.breakpoints.down("sm")]: {
    marginTop: darkTheme.spacing(4),
    width: '95%',
    padding: darkTheme.spacing(3),
    
  },
};

function SignInModal(props) {  
  const { open, onClose, setOpen, disableCancelButton } = props;
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);

  const Router = useRouter();

  function onChangePhoneHandler(e) {
    setValue(e);
  }

  function onCloseOTPHandler(e) {
    setModalOTP(false);
    setLoading(true);
    // if(props.redirectUrl !=''){
    //   Router.push(props.redirectUrl)
    // }
  }

  const onSubmitLogin = async(event) => {
    event.preventDefault();
    let mobile = value;
    setLoading(true);
    if (mobile == "") {
      toast.error(`Please Enter Valid Mobile Number`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setLoading(false);
    } else {
      axios.get(`/api/auth/msg91/otp?mobile=${mobile}`).then((response) => {        
        if(response.data.type == 'success'){
          setLoading(true);
          setModalOTP(true);
          // setOpen(false);
        } else {
          toast.error(`Please Try Again`, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  };
  return (
    <Fragment>
      <LoginOTP open={modalOTP} onClose={onCloseOTPHandler} mobile={value} />
      <Typography component="div">
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"          
          sx={{
            ...signInContainer,
            backdropFilter: "blur(5px)",
          }}
        >
          <DialogContent>
            <Box maxWidth={"xl"} component="div">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="xl"                         
                >                  
                <Typography component="div" sx={loginCardContent} >
                      {/* {disableCancelButton ? ('') : (                       */}
                        <Typography component="div" sx={{ width: '100%', textAlign: 'right', mr:-1 }}>
                          <CloseIconNew sx={{ fontSize: 16, cursor: 'pointer'}} onClick={onClose} />
                        </Typography>
                      {/* )} */}
                    <Grid container spacing={0}> 
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="desktopMode">
                      <Typography component="div"
                        sx={{
                          background: '#151414',
                          borderRadius: 10,
                          p: 2,   
                          mb:2                       
                        }}>
                        <Image src={SignInImage} alt="..." width={100} height={120} layout="responsive" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center">
                      <Typography component="div" sx={{width:{md:'90%'}, ml:{md:3}}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="mobileMode">
                          <Typography component="div"
                            sx={{
                              background: '#151414',
                              borderRadius: 6,
                              p: 1,    
                              display: 'table',
                              margin:'0 auto'
                            }}>
                            <Image src={SignInImage} alt="..." width={120} height={120} align="center" />
                          </Typography>
                        </Grid>
                        <Typography variant="h4" sx={{mb:darkTheme.spacing(2)}}>SignIn / SignUp</Typography>
                        <form onSubmit={onSubmitLogin}>
                          <Typography variant="h6" sx={{mb:1}}>
                            Enter Mobile Number
                          </Typography>
                          <PhoneInput
                            country={"in"}
                            onChange={onChangePhoneHandler}
                            onEnterKeyPress={onSubmitLogin}
                            value={value}
                            countryCodeEditable={false}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true
                          }}
                          style={{background:'unset !important'}}
                          />                          
                            <Stack direction="row" justifyContent="center" sx={{mt:5, mb:3}}>
                              <LoadingButton
                                className="themeBtn"
                                variant="contained"
                                loading={loading}
                                onClick={onSubmitLogin}
                                size="large"
                                loadingPosition="end"  
                                sx={{py:2, px:4, borderRadius:10}}                          
                              >
                                REQUEST OTP
                              </LoadingButton>
                            </Stack>                          
                        </form>
                      </Typography>  
                    </Grid>
                   </Grid>  
                </Typography>   
              </Stack>              
            </Box>
          </DialogContent>
        </Dialog>
      </Typography>
    </Fragment>
  );
}
export default SignInModal;
