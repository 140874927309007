import React, { useState, useEffect } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import List from "@mui/material/List";
import SignInModal from "../modals/SignInModal";
// import CityModal from '../modals/CityModal'
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import darkTheme from "../../src/theme";
import ClubsIcon from "../../public/images/mobile/Clubs.svg";
import DealsIcon from "../../public/images/mobile/Deals.svg";
import EventsIcon from "../../public/images/mobile/Events.svg";
import ProfileIcon from "../../public/images/mobile/Profile.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import LocationIcon from "../../public/images/location.svg";
import LocationDropdown from "../../components/LocationDropdown.js";
import axios from "axios";

const headerContainer = {
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: 2,
  // borderBottom: `1px solid #3C3C3C`,
};

const logoContainer = {
  padding: 1,
  display: "flex",
  alignSelf: "center",
};

const menuContainerList = {
  "& ul": {
    listStyle: "none",
    alignItems: "baseline",
    display: "flex",
    "& li": {
      display: "inline-flex",
      background: "transparent",
      margin: "auto 30px",
      "&:last-child": {
        margin: "auto 0px auto 30px",
      },
      "& img": {
        maxWidth: "20px",
        minWidth: "20px",
      },
      "& a": {
        color: darkTheme.palette.grey[200],
        fontSize: 14,
        letterSpacing: 0.75,
      },
      "& :hover": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
      },
      "& :active": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
      },
      "& .active": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
      },
    },
  },
};

const profileDropdown = {
  background: darkTheme.palette.background.alternate,
  minWidth: "200px",
  "& ul": {
    paddingLeft: 0,
    color: `${darkTheme.palette.common.white} !important`,
    "& a": {
      color: `${darkTheme.palette.common.white} !important`,
    },
  },
};

const menuContainer = {
  [darkTheme.breakpoints.down("md")]: {
    display: "none",
  },
};
const bottomMenuContainer = {
  display: "none",
  [darkTheme.breakpoints.down("md")]: {
    display: "block",
  },
};

const bottomNavLabel = {
  textTransform: "capitalize",
  fontWeight: 500,
  fontSize: 14,
};

const Navbar = () => {
  const [bgClass, setBgClass] = useState("");
  const [cityName, setCityName] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  // const [openCity, setOpenCity] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpen2, setMenuOpen2] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [anchorE2, setAnchorE2] = useState();
  const cookieUser = parseCookies();
  const cookieCity = parseCookies();
  const { asPath, pathname } = useRouter();
  const [cities, setCities] = useState([]);

  const router = useRouter();

  useEffect(() => {
    axios
      .get("/api/cities/list", {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setCities(response.data ? response.data.result : []);
          // setLoading(false)
        } else {
          // setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const city = cookieCity.city ? cookieCity.city : "";
    Cookies.set("city", city, {
      expiredIn: "5h",
      secure: true,
      sameSite: "none",
    });
    setCityName(city);
  }, []);

  useEffect(() => {
    const new_user = cookieUser.user ? JSON.parse(cookieUser.user) : "";
    if (new_user) {
      setLoggedInUser(new_user);
    }
    const city = cookieCity.city ? cookieCity.city : "";
    if (city) {
      setCityName(city);
    } else {
      setCityName("");
    }

    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  const listenScrollEvent = (e) => {
    if (window.scrollY > 30) {
      setBgClass("bg-dark");
    } else {
      setBgClass("");
    }
  };
  const handleModelOpen = () => {
    setOpen(true);
  };

  const handleModelClose = () => {
    setOpen(false);
  };

  const logOutUser = (e) => {
    e.preventDefault();
    Cookies.remove("user");
    Cookies.remove("token");
    setLoggedInUser();

    if (cityName) {
      router.push("/" + cityName + "/home", null, { shallow: false });
    } else {
      router.push("/", null, { shallow: false });
    }
  };

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  let closeMenu = () => {
    setMenuOpen(false);
  };

  const recordButtonPosition2 = (event) => {
    setAnchorE2(event.currentTarget);
    setMenuOpen2(true);
  };

  let closeMenu2 = () => {
    setMenuOpen2(false);
  };

  // const handleModelCloseCity = () => {
  //   setOpenCity(false);
  // }

  // const handleModelOpenCity = () => {
  //   setOpenCity(true)
  // }

  return (
    <Typography component="div" sx={headerContainer}>
      <Typography component="div" className={bgClass}>
        <Box>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {router.pathname == "/mag/[slug]" ||
            router.pathname == "/event/[...slug]" ||
            router.pathname == "/venue/[...slug]" ||
            router.pathname == "/deal/[...slug]" ? (
              <>
                <Typography component="div" className="mobileMode">
                  {router.pathname == "/mag/[slug]" && (
                    <Link href={"/mag"} as={"/mag"} sx={{ flexGrow: "2" }}>
                      <a
                        style={{
                          padding: "8px 0",
                          display: "block",
                          color: darkTheme.palette.common.white,
                        }}
                      >
                        <ArrowBackIcon />
                      </a>
                    </Link>
                  )}

                  {
                    router.pathname == "/event/[...slug]" && ""
                    // <Link href={cityName == '' ? "/all-events" : "/" + cityName + "/all-events"} as={cityName == '' ? "/" : "/" + cityName + "/all-events"} sx={{ flexGrow: '2' }}>
                    //   <a style={{ 'padding': '8px 0', 'display':'block', color: darkTheme.palette.common.white }}>
                    //     <ArrowBackIcon />
                    //   </a>
                    // </Link>
                  }

                  {
                    router.pathname == "/venue/[...slug]" && ""
                    // <Link href={cityName == '' ? "/venues" : "/" + cityName + "/venues"} as={cityName == '' ? "/" : "/" + cityName + "/venues"} sx={{ flexGrow: '2' }}>
                    //   <a style={{ 'padding': '8px 0', 'display':'block', color: darkTheme.palette.common.white }}>
                    //     <ArrowBackIcon />
                    //   </a>
                    // </Link>
                  }

                  {
                    router.pathname == "/deal/[...slug]" && ""
                    // <Link href={cityName == '' ? "/deals" : "/" + cityName + "/deals"} as={cityName == '' ? "/" : "/" + cityName + "/deals"} sx={{ flexGrow: '2' }}>
                    //   <a style={{ 'padding': '8px 0', 'display':'block', color: darkTheme.palette.common.white }}>
                    //     <ArrowBackIcon />
                    //   </a>
                    // </Link>
                  }
                </Typography>
                <Typography component="div" className="desktopMode">
                  <Link
                    href={cityName == "" ? "/home" : "/hom" + cityName + "/home"}
                    as={cityName == "" ? "/home" : "/" + cityName + "/home"}
                    sx={{ flexGrow: "2" }}
                  >
                    <a>
                      <Typography component="div" sx={logoContainer}>
                        <Image
                          src="/images/new-logo.png"
                          alt="clubr-logo"
                          width={125}
                          height={40}
                          layout="fixed"
                          priority
                          sx={{ p: 1 }}
                        />
                      </Typography>
                    </a>
                  </Link>
                </Typography>
              </>
            ) : (
              <>
              <Link
                href={cityName == "" ? "/home" : "/" + cityName + "/home"}
                as={cityName == "" ? "/home" : "/" + cityName + "/home"}
                sx={{ flexGrow: "2" }}
              >
                <a>
                  <Typography component="div" sx={logoContainer}>
                    <Image
                      src="/images/new-logo.png"
                      alt="clubr-logo"
                      width={125}
                      height={40}
                      layout="fixed"
                      priority
                      sx={{ p: 1 }}
                    />
                  </Typography>
                </a>
              </Link>
                <Typography component="div" sx={{textTransform:'capitalize !important'}} className="mobileMode">
                  <LocationDropdown cities={cities} />
                </Typography>
              </>
            )}
            <Typography component="div" sx={menuContainer}>
              <Typography component="div" sx={menuContainerList}>
                <Typography component="ul">
                  <li>
                    <Link
                      href={
                        cityName == ""
                          ? "/all-events"
                          : "/" + cityName + "/all-events"
                      }
                    >
                      <a
                        className={
                          asPath.includes("/" + cityName + "/all-events")
                            ? "active"
                            : "" || asPath.includes("/" + cityName + "/events")
                            ? "active"
                            : "" || asPath.includes("/all-events")
                            ? "active"
                            : "" || asPath.includes("/events")
                            ? "active"
                            : ""
                        }
                      >
                        Events
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={
                        cityName == "" ? "/venues" : "/" + cityName + "/venues"
                      }
                    >
                      <a
                        className={
                          asPath.includes("/" + cityName + "/venues")
                            ? "active"
                            : ""
                        }
                      >
                        Venues
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={
                        cityName == "" ? "/deals" : "/" + cityName + "/deals"
                      }
                    >
                      <a
                        className={
                          asPath.includes("/" + cityName + "/deals")
                            ? "active"
                            : ""
                        }
                      >
                        Deals
                      </a>
                    </Link>
                  </li>
                  {loggedInUser ? (
                    <>
                      <li
                        // onClick={recordButtonPosition}
                        onMouseOver={recordButtonPosition}
                        onMouseOut={closeMenu}
                        // passHref
                      >
                        <Tooltip title="Account settings">
                          <a>Profile</a>
                        </Tooltip>
                        <Menu
                          id="account-menu"
                          anchorEl={anchorEl}
                          hideBackdrop={false}
                          open={menuOpen}
                          onClose={closeMenu}
                          onMouseOut={closeMenu}
                          // keepMounted
                          onMouseLeave={closeMenu}
                          PaperProps={{
                            elevation: 1,
                            sx: {
                              overflow: "visible",
                              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,1))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: darkTheme.palette.background.alternate,
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Typography component="div" sx={profileDropdown}>
                            <MenuItem>
                              <List href="/" passHref>
                                <a
                                  href="/profile"
                                  style={{
                                    color: darkTheme.palette.primary.main,
                                  }}
                                >
                                  Profile{" "}
                                </a>
                              </List>
                            </MenuItem>
                            <MenuItem>
                              <List href="/" passHref>
                                <a
                                  href="/bookings"
                                  style={{
                                    color: darkTheme.palette.primary.main,
                                  }}
                                >
                                  Bookings{" "}
                                </a>
                              </List>
                            </MenuItem>
                            <MenuItem>
                              <List href="/" passHref>
                                <a
                                  href="/"
                                  onClick={logOutUser}
                                  style={{
                                    color: darkTheme.palette.primary.main,
                                  }}
                                >
                                  Logout
                                </a>
                              </List>
                            </MenuItem>
                          </Typography>
                        </Menu>
                      </li>
                    </>
                  ) : (
                    <li onClick={handleModelOpen}>
                      <a>SignIn</a>
                    </li>
                  )}
                  <li style={{ textTransform: "capitalize" }}>
                    {/* <Image src={LocationIcon} alt="" width={12} height={12} /> */}
                    {router.pathname !== "/bk/[id]" &&
                    router.pathname !== "/profile" &&
                    router.pathname !== "/bookings" && (
                      <LocationDropdown cities={cities} />
                    )}
                    {/* <a variant="link" onClick={handleModelOpenCity}>
                      <Image src={LocationIcon} alt="" width={12} height={12} /><span style={{ 'textTransform': 'capitalize !important' }}>{Cookies.get('city') ? Cookies.get('city') : 'All Cities'}</span></a>                     */}
                  </li>
                </Typography>
              </Typography>
            </Typography>
            {router.pathname == "/mag/[slug]" ||
            router.pathname == "/event/[...slug]" ||
            router.pathname == "/venue/[...slug]" ||
            router.pathname == "/deal/[...slug]" ||
            router.pathname == "/bk/[id]" ||
            router.pathname !== "/profile" ||
            router.pathname !== "/bookings" ? (
              ""
            ) : (
              <Typography
                component="div"
                className="mobileMode"
                sx={menuContainerList}
                style={{ alignSelf: "end" }}
              >
                <Typography component="ul" sx={{ mb: 1 }}>
                  <li style={{ textTransform: "capitalize" }}>
                    {/* <Image src={LocationIcon} alt="" width={12} height={12} /> */}
                    <LocationDropdown cities={cities} />
                    {/* <a variant="link" onClick={handleModelOpenCity}>
                          <Image src={LocationIcon} alt="" width={12} height={12} /><span style={{ 'textTransform': 'capitalize !important' }}>{Cookies.get('city') ? Cookies.get('city') : 'All Cities'}</span></a>                     */}
                  </li>
                </Typography>
              </Typography>
            )}
          </Container>
        </Box>

        {router.pathname == "/event/[...slug]" ||
        router.pathname == "/venue/[...slug]" ||
        router.pathname == "/deal/[...slug]" ||
        router.pathname == "/cart/[id]" ? (
          ""
        ) : (
          <Typography component="div" sx={bottomMenuContainer}>
            <BottomNavigation
              showLabels
              sx={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 2,
                position: "fixed",
                background: darkTheme.palette.common.black,
                padding: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <BottomNavigationAction
                label="Events"
                icon={<Image src={EventsIcon} />}
                onClick={() => router.push("/" + cityName + "/all-events")}
                sx={bottomNavLabel}
              />
              <BottomNavigationAction
                label="Venues"
                icon={<Image src={ClubsIcon} />}
                onClick={() => router.push("/" + cityName + "/venues")}
                sx={bottomNavLabel}
              />
              <BottomNavigationAction
                label="Deals"
                icon={<Image src={DealsIcon} />}
                onClick={() => router.push("/" + cityName + "/deals")}
                sx={bottomNavLabel}
              />
              {loggedInUser ? (
                <BottomNavigationAction
                  label="Profile"
                  icon={<Image src={ProfileIcon} />}
                  onClick={recordButtonPosition2}
                  sx={bottomNavLabel}
                />
              ) : (
                <BottomNavigationAction
                  label="Profile"
                  icon={<Image src={ProfileIcon} />}
                  onClick={handleModelOpen}
                  sx={bottomNavLabel}
                />
              )}
              <Menu anchorEl={anchorE2} open={menuOpen2} onClose={closeMenu2}>
                <Typography component="div" sx={profileDropdown}>
                  <MenuItem>
                    <List href="/profile" passHref>
                      <Link href="/profile">Profile</Link>
                    </List>
                  </MenuItem>
                  <MenuItem>
                    <List href="/bookings" passHref>
                      <Link href="/bookings">Bookings </Link>
                    </List>
                  </MenuItem>
                  <MenuItem onClick={logOutUser}>Logout </MenuItem>
                </Typography>
              </Menu>
            </BottomNavigation>
          </Typography>
        )}
        <SignInModal
          open={open}
          onClose={handleModelClose}
          disableCancelButton={false}
        />
        {/* <CityModal open={openCity} onClose={handleModelCloseCity} />       */}
      </Typography>
    </Typography>
  );
};
export default Navbar;
