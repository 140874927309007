import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Layout from "../components/Layout";
import { useRouter, asPath } from "next/router";
import ErrorBoundary from "../components/ErrorBoundary";
import Script from "next/script";
import "../styles/globals.css";
import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import { parseCookies } from "nookies";
import Cookies from "js-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../src/theme";
import CircularProgress from "@mui/material/CircularProgress";
// import axios from "axios"
import CityModal from "../components/modals/CityModal";
import { server } from "../config";
import axios from "axios";
// import AppStore from '../context/category'

export default function MyApp(props) {
  const { Component, pageProps } = props;
  const [cityName, setCityName] = useState();
  const [open, setOpen] = useState(false);
  const cookieCity = parseCookies();
  const Router = useRouter();
  const canonicalUrl = (
    `https://clubr.in` + (Router.asPath === "/" ? "" : Router.asPath)
  ).split("?")[0];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => setLoading(true));
    Router.events.on("routeChangeComplete", () => setLoading(false));
    Router.events.on("routeChangeError", () => setLoading(false));
    return () => {
      Router.events.off("routeChangeStart", () => setLoading(true));
      Router.events.off("routeChangeComplete", () => setLoading(false));
      Router.events.off("routeChangeError", () => setLoading(false));
    };
  }, [Router.events]);

  useEffect(() => {
    if (
      // Router.pathname == "/" ||
      Router.pathname == "/home" ||
      Router.pathname == "/all-events" ||
      Router.pathname == "/events" ||
      Router.pathname == "/clubs" ||
      Router.pathname == "/deals" ||
      Router.pathname == "/[cityTitle]/all-events" ||
      Router.pathname == "/[cityTitle]/events" ||
      Router.pathname == "/[cityTitle]/clubs" ||
      Router.pathname == "/[cityTitle]/deals"
      // ||
      // Router.pathname == "/event/[...slug]" ||
      // Router.pathname == "/club/[...slug]" ||
      // Router.pathname == "/deal/[...slug]"
    ) {
      const cityName = cookieCity.city ? cookieCity.city : "";
      if (cityName != "" && cityName != "undefined") {
        Cookies.set("city", cityName, {
          expiredIn: "5h",
          secure: true,
          sameSite: "none",
        });
        setCityName(cityName);
      } else {
        // BRAND NEW CODE:
        // console.log('-----------------------------');
        // console.log(cookieCity['location-details']);
        axios
          .get("/api/checkLocation")
          .then((response) => {
            if (Cookies.get("firstTimeOver")) {
            } else {
              Cookies.set("firstTimeOver", "true", {
                expiredIn: "5h",
                secure: true,
                sameSite: "none",
              });
              // console.log('response');
              // console.log(response.data);
              const { city, country, countryRegion, region } = response.data;

              if (countryRegion == "TG") {
                // if telangana
                setOpen(false);
                Cookies.set("city", "hyderabad", {
                  expiredIn: "5h",
                  secure: true,
                  sameSite: "none",
                });
                window.location.href = server + "/hyderabad/home";
              } else if (countryRegion == "GA") {
                // if goa
                setOpen(false);
                Cookies.set("city", "goa", {
                  expiredIn: "5h",
                  secure: true,
                  sameSite: "none",
                });
                window.location.href = server + "/goa/home";
              } else if (countryRegion == "KA") {
                // if karnataka
                setOpen(false);
                Cookies.set("city", "bengaluru", {
                  expiredIn: "5h",
                  secure: true,
                  sameSite: "none",
                });
                window.location.href = server + "/bengaluru/home";
            //   } else if (countryRegion == "MH") {
            //     // if maharashtra
            //     setOpen(false);
            //     Cookies.set("city", "mumbai", {
            //       expiredIn: "5h",
            //       secure: true,
            //       sameSite: "none",
            //     });
            //     window.location.href = server + "/mumbai/home";
              } else {
                // window.location.href = server + "/home";
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setOpen(true);
          });

        // // NEW CODE:
        // // console.log('-----------------------------');
        // // console.log(cookieCity['location-details']);
        // // axios.get('/api/checkLocation')
        // //     .then((response) => {
        //         // console.log('response');
        //         // console.log(response.data);
        //         // const a = response.data;
        //         const a = cookieCity['location-details'] ? {locationAccess: true, locationDetails: JSON.parse(cookieCity['location-details'])} : {locationAccess: false, locationDetails: {}}

        //         if (a.locationAccess == false) {
        //             // setOpen(true);
        //         } else {
        //             const {city, region, country} = a.locationDetails;

        //             if (region == "TG") {   // if telangana
        //                 setOpen(false);
        //                 Cookies.set('city', "hyderabad", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                 window.location.href = server + '/hyderabad/home';
        //             } else if (region == "GA") {  // if goa
        //                 setOpen(false);
        //                 Cookies.set('city', "goa", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                 window.location.href = server + '/goa/home';
        //             } else if (region == "KA") {  // if karnataka
        //                 setOpen(false);
        //                 Cookies.set('city', "banglore", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                 window.location.href = server + '/banglore/home';
        //             } else if (region == "MH") {    // if maharashtra
        //                 setOpen(false);
        //                 Cookies.set('city', "mumbai", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                 window.location.href = server + '/mumbai/home';
        //             } else {
        //                 // setOpen(true);
        //             }
        //         }

        //     // })
        //     // .catch(err => {
        //     //     console.log(err);
        //     //     setOpen(true);
        //     // })

        // OLD CODE:
        // navigator.geolocation.getCurrentPosition(function (position) {
        //     axios.get('https://geolocation-db.com/json/')
        //         .then((response) => {
        //             console.log('response');
        //             console.log(response);
        //             if (response.status == 200) {
        //                 if (response.data.state == "Andhra Pradesh" || response.data.state == "Telangana") {
        //                     setOpen(false);
        //                     Cookies.set('city', "hyderabad", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                     window.location.href = server + '/hyderabad/home';
        //                 }
        //                 else if (response.data.state == "Goa") {
        //                     setOpen(false);
        //                     Cookies.set('city', "goa", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                     window.location.href = server + '/goa/home';
        //                 } else if (response.data.state == "Karnataka") {
        //                     setOpen(false);
        //                     Cookies.set('city', "banglore", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                     window.location.href = server + '/banglore/home';
        //                 } else if (response.data.state == "Maharastra") {
        //                     setOpen(false);
        //                     Cookies.set('city', "mumbai", { expiredIn: '5h', secure: true, sameSite: "none" });
        //                     window.location.href = server + '/mumbai/home';
        //                 } else {
        //                     setOpen(true);
        //                 }
        //             } else {
        //                 setOpen(true);
        //             }
        //         })
        // });
      }
    }

    // if (Router.pathname == "/" || Router.pathname == "/home") {
      if (Router.pathname == "/home") {
      const cityName = cookieCity.city ? cookieCity.city : "";
      if (cityName != "") {
        Router.push(cityName + "/home");
      }
    }
  }, [Router.pathname]);

  const handleModelClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
              overflow: "hidden",
              background: "#00000058",
              opacity: 0.5,
            }}
          >
            <CircularProgress />
          </Typography>
        </ThemeProvider>
      ) : (
        <>
          <Head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0 user-scalable=0"
            />
            <meta
              httpEquiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
            <meta
              name="facebook-domain-verification"
              content="1ufroljw5ps9jeg9x67pdjagl55sxs"
            />
            {/* <link href="../css/main.css" rel="stylesheet" type="text/css"/>     
                            <link href="../css/cards.css" rel="stylesheet" type="text/css"/>     
                            <link href="../css/carousel.css" rel="stylesheet" type="text/css" />  */}
            {/* <script async src="https://cdn.ampproject.org/v0.js"></script>
                            <script async custom-element="amp-story-player" src="https://cdn.ampproject.org/v0/amp-story-player-0.1.js"></script>        
                            <script async src="https://cdn.ampproject.org/amp-story-player-v0.js"></script>
                            <script async custom-element="amp-carousel" src="https://cdn.ampproject.org/v0/amp-carousel-0.1.js"></script>
                            <script async src="https://000615502.deployed.codepen.website/main.js" type="module"></script>   */}
            <link rel="canonical" href={canonicalUrl} />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>
              <ErrorBoundary>
                <CityModal open={open} onClose={handleModelClose} />
                <Component {...pageProps} />
              </ErrorBoundary>
            </Layout>
          </ThemeProvider>
          <Script
            id="google-tag-script"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KKPK6B7');`,
            }}
          />
        </>
      )}
    </>
  );
}
