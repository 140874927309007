import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import darkTheme from '../src/theme';
import Image from 'next/image'
import LocationIcon from '../public/images/location.svg'
import { useRouter, asPath } from 'next/router';
import Cookies from 'js-cookie'
import { parseCookies } from 'nookies'
import { server } from '../config';

const locationItem = {
    width: 12,
    height: 12,
    borderRadius: 50,
    background: `transparent`,
    border: `2px solid ${darkTheme.palette.background.ticket}`,
    display: 'inline-block',
    mr: 1,
    p: 1,  
    '& :hover': {
        border: `2px solid ${darkTheme.palette.primary.main}`,
    }
};
const locationItemActive = {
    width: 12,
    height: 12,
    borderRadius: 50,
    background: `transparent`,
    border: `2px solid ${darkTheme.palette.primary.main}`,
    display: 'inline-block',
    mr: 1,
    p: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export default function LocationDropdown(props) {
    // console.log(props);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [ cityName, setCityName ] = useState(''); 
    const open = Boolean(anchorEl);
    const cookieCity = parseCookies();   

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        let newCity = selectedIndex;        
        newCity = props.cities.filter(x => x.name.toLowerCase() == cookieCity.city).map(x=>x.id)        
        setSelectedIndex(newCity[0]);
    },[props.cities])
    
    
    const handleMenuItemClick = (event, id, value) => {        
        setSelectedIndex(id);            
        setAnchorEl(null);
        let city = value;
        // alert(city)
        if (city != 'all cities') {
            setCityName(city);  
            Cookies.set('city', city, { expiredIn: '5h', secure: true, sameSite: "none" });
        } else {
            city = '';
            setCityName(city);  
            Cookies.remove('city');
        }
        if (asPath) {
            const arr = asPath.split("/")
            const lastSegment = arr.pop()
            if (
            arr[1] == 'home' ||
            arr[1] == 'events' ||
            arr[1] == 'events' ||
            arr[1] == '' ||
            arr[1] == 'clubs' ||
            arr[1] == 'deals'
            ) {
            window.location.href = `${server}/${city}/${lastSegment}`;
            } else {
            window.location.href = `${server}/${city}/home`;
            }
        } else {
            window.location.reload()
        }
    };

    

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
            bgcolor: 'transparent',
            padding: 0,
            display: 'flex',
            alignItems:'center'
        }}
          >
        <Image src={LocationIcon} alt="" width={12} height={12} />
        <ListItem
            button
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"          
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}
            sx={{
                backgroundColor: 'transparent',
                padding: 0,
                textTransform:'capitalize !important'
            }}
        >
            {cookieCity.city ? (
                <ListItemText
                    align="right"
                    primary={cookieCity.city != 'undefined' ? cookieCity.city : 'Location'}
                />
            ) : (
                <ListItemText
                    align="right"
                    primary={'Location'}
                />
            )}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{          
            role: 'listbox',
            minWidth: '300px',                                     
        }}
    >        
        <MenuItem
            key="0"           
            selected={selectedIndex == 0}
            onClick={(event) => handleMenuItemClick(event, 0, 'all cities')}                
          >
            <Stack component="div" alignItems="center" display="flex" flexDirection="row" sx={{ textTransform: 'capitalize' }}>
                {selectedIndex == 0 && !cookieCity.city ? (
                    <Typography component="div" sx={locationItemActive}>
                        <Typography component="div"                            
                            sx={{                                    
                                width: 5,
                                height: 5,
                                border: `unset`,
                                mr: 0,
                                p: 0.8,    
                                borderRadius:50,
                                background:`${darkTheme.palette.primary.main}`
                            }}
                        ></Typography>
                    </Typography>
                ) : (
                    <Typography component="div" sx={locationItem}></Typography>    
                )}
                All Cities
            </Stack> 
        </MenuItem>      

        {props.cities.map((option) => (
          <MenuItem
            key={option.id}            
            selected={option.id == selectedIndex}
            onClick={(event) => handleMenuItemClick(event, `${option.id}`, `${option.name.toLowerCase()}`)}                
          >
                <Stack component="div" alignItems="center" display="flex" flexDirection="row" sx={{textTransform:'capitalize !important'}}>
                    {option.id == selectedIndex && selectedIndex > 0 ? (
                        <Typography component="div" sx={locationItemActive}>
                            <Typography component="div"
                                sx={{                                    
                                    width: 5,
                                    height: 5,
                                    border: `unset`,
                                    mr: 0,
                                    p: 0.8,    
                                    borderRadius:50,
                                    background:`${darkTheme.palette.primary.main}`
                                }}                                
                            ></Typography>
                        </Typography>
                     ) : (
                        <Typography component="div" sx={locationItem}></Typography>    
                    )}
                    {option.name.toLowerCase()}
                </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}