import React, {
  useState,
  useRef,  
  Fragment,
} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "js-cookie";
import axios from "axios";
import darkTheme from '../../src/theme';
import { useRouter } from "next/router";
// import CloseIconNew from "@mui/icons-material/Close";
import SignInImage from "../../public/images/SignInImage.png";
import Image from "next/image";

const signInContainer = {
  padding: 0,
  background: `rgba(0,0,0,0.75)`,
  '& h4': {
    fontSize: 30,
    fontWeight: 700,
    color: darkTheme.palette.primary.title,
    marginBottom: darkTheme.spacing(3),
    [darkTheme.breakpoints.down("sm")]: {
      marginTop: darkTheme.spacing(2),
      marginBottom: darkTheme.spacing(1),
      fontSize: 22,
      fontWeight: 500,
    },
  },
  '& h6': {
    fontSize: 16,
    color: darkTheme.palette.primary.location,
    fontWeight: 300,
    [darkTheme.breakpoints.down("sm")]: {
      marginBottom: darkTheme.spacing(1),
      fontSize: 14,
      fontWeight: 100,
    },
  },
}; 
const loginCardContent = {
  backgroundColor: darkTheme.palette.background.alternate,
  opacity: 1,
  border: `1px solid ${darkTheme.palette.background.ticket}`,  
  borderRadius: 3,   
  width: '55%',
  minHeight: '60vh',
  display: 'block',
  marginTop: darkTheme.spacing(10),
  padding: darkTheme.spacing(3),
  [darkTheme.breakpoints.down("md")]: {
    width: '75%',
    padding: darkTheme.spacing(5),
    marginTop: darkTheme.spacing(3),
  },
  [darkTheme.breakpoints.down("sm")]: {
    marginTop: darkTheme.spacing(4),
    width: '95%',
    padding: darkTheme.spacing(3),
    
  },
};

function UserModal(props) { 
  const Router = useRouter();
  const { openUser, onCloseUser } = props;
  // const [loggedInUser, setLoggedInUser] = useState()
  const [loading, setLoading] = useState(false);
  let nameInputElement = useRef();
  let emailInputElement = useRef();
  let userIdInputElement = useRef();

  const onUpdateUser = (event) => {    
      event.preventDefault();
      let name = nameInputElement.current?.value;
      let email = emailInputElement.current?.value;
      let userId = userIdInputElement.current?.value;    
      if (name == "") {
        toast.error(`Please Enter Name`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
      } else if (email == "") {
        toast.error(`Please Enter Valid Email`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
      } else {
        setLoading(true);
        axios
          .post(`/api/auth/updateUser`, {
            data: {
              email: email,
              full_name: name,
              userId: userId,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status == 1) {
                let new_user = response.data.user ? response.data.user[0] : null;
                cookie.set("token", response.data.token);
                cookie.set("user", JSON.stringify(new_user));
                setLoading(false);
                onCloseUser();     
                // setLoggedInUser(new_user)
                Router.reload();
              } else {
                toast.error(`Something Went Wrong, Please Try again`, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                setLoading(false);
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    };
  return (
    <Fragment>
      <Typography component="div">
        <Dialog
          fullScreen
          open={openUser}
          onClose={!openUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            ...signInContainer,
            backdropFilter: "blur(5px)",
          }}
        >
          <DialogContent>
            <Box maxWidth={"xl"} component="div">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="xl"                         
                >                  
                <Typography component="div" sx={loginCardContent} >
                      {/* {disableCancelButton ? ('') : (                      
                        <Typography component="div" sx={{ width: '100%', textAlign: 'right', mr:-1 }}>
                          <CloseIconNew sx={{ fontSize: 16, cursor: 'pointer'}} onClick={onClose} />
                        </Typography>
                      )} */}
                    <Grid container spacing={1}> 
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="desktopMode">
                      <Typography component="div"
                        sx={{
                          background: '#151414',
                          borderRadius: 10,
                          p: 2,   
                          mb:2                       
                        }}>
                        <Image src={SignInImage} alt="..." width={100} height={120} layout="responsive" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center">
                      <Typography component="div" sx={{width:{md:'90%'}, ml:{md:3}}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="mobileMode">
                          <Typography component="div"
                            sx={{
                              background: '#151414',
                              borderRadius: 6,
                              p: 1,    
                              display: 'table',
                              margin:'0 auto'
                            }}>
                            <Image src={SignInImage} alt="..." width={120} height={120} align="center" />
                          </Typography>
                        </Grid>
                        <Typography variant="h4" sx={{mb:darkTheme.spacing(2)}}>Enter Details</Typography>
                        <form method="POST">                        
                          <input
                            type="hidden"
                            className="custom-form-control"
                            ref={userIdInputElement}
                            value={props.userId}
                            name="userId"
                            id="userId"
                            placeholder="UserId"
                            required
                          />
                          <input
                            type="text"
                            className="custom-form-control"
                            ref={nameInputElement}
                            name="full_name"
                            id="full_name"
                            placeholder="Full Name"
                            required
                          />
                          <input
                            type="email"
                            className="custom-form-control"
                            ref={emailInputElement}
                            name="email"
                            id="email"
                            placeholder="Email ID"
                            required
                          />
                            <Stack direction="row" justifyContent="center" mt={darkTheme.spacing(5)} sx={{width:'100%'}}>
                            <LoadingButton
                              className="themeBtn"
                              variant="contained"
                              loading={loading}
                              onClick={onUpdateUser} 
                              loadingPosition="end"
                              size="large"
                              sx={{py:2, px:8, borderRadius:10}}
                            >
                              Submit
                            </LoadingButton>
                          </Stack>
                        </form>
                      </Typography>  
                    </Grid>
                   </Grid>  
                </Typography>   
              </Stack>              
            </Box>
          </DialogContent>
        </Dialog>
      </Typography>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
    </Fragment>
  );
}
export default UserModal;
