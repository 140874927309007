import { createTheme } from '@mui/material';
import { red } from "@mui/material/colors";

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 600;
const fontWeightBold = 800;

const black = "#000";
const white = "#fff";
const gray = "#484848";
const light = "#545454";
const green = "#34c759";
const borderColor = '#3F3F3F'

export const darkTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 991,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode: 'dark',   
        common: {
          black: `${black}`,
          white: `${white}`,
          gray: `${gray}`,
          light: `${light}`,
          green: `${green}`,
        },
        primary: {
          main: '#61FC6E',
          contrastText: '#fff',
          arrowColor: '#1B1818',
          location: '#909090',
          title: '#F6F6F6',
          subTitle: '#909090',          
          dates: '#DFDFDF',          
        },
        secondary: {
            dark: '#ffffff90',
            main: '#ffffff90',
            light: '#ffffff90',
        },
        error: {
            main: '#19857b',
        },
        background: {
            default: '#151414',
            alternate: '#1D1D1D',
            secondary: '#343434',
            tickets: '#1D1D1D',
            ticket: '#5D5D5D',
            signInCard:'#1D1D1D'
        },
        green: {
          main: `${green}`,
        },
        light: {
          main: `${light}`,
        },
        error: {
          main: red.A400,
        },
        borderLight: {
          main: `${borderColor}`
        }        
    },    
    typography: {
        "margin":"0 auto",
        "fontFamily": `"DM Sans", sans-serif`,
        "fontSize": 14,
        "fontWeight": fontWeightRegular,   
        h1: {
          fontSize: 30,  
          fontWeight: fontWeightBold,         
        },
        h2: {
          fontSize: 28,  
          fontWeight: fontWeightBold,         
        },
        h3: {
          fontSize: 26,  
          fontWeight: fontWeightMedium,         
        },
        h4: {
          fontSize: 24,  
          fontWeight: fontWeightMedium,         
        },
        h5: {
          fontSize: 22,  
          fontWeight: fontWeightLight,         
        },
        h6: {
          fontSize: 20,  
          fontWeight: fontWeightLight,         
        },
        subtitle1: {
          fontSize: 12,
        },        
    },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          borderRadius: '20px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: 20
        }
      }
    },
      
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'transparent',
          maxWidth: '100%',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: '0 !important',
            marginBottom: 0
          }
        }
      },
      '& .form-control': {
        background: 'transparent'
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }
      }
    },    
    MuiMenuItem:{
      styleOverrides:{
        root: {
          borderRadius: 0,
          padding: 12,
          background: '#1D1D1D',
          ":hover": {
            backgroundColor: "#343434",            
          },          
          "&.Mui-selected":{
            backgroundColor: "#343434",            
            ":hover": {
              backgroundColor: "#343434",              
            },
          }
        }
      }
    },     

    MuiAccordion: {
      styleOverrides: {
        root: {
          ':first-of-type': {
            borderRadius: 8,
          }
        }
      }
    },
    MuiInputLabel: {
      overrides: {
        root: {
          color: `${light}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ ownerState, theme }) => {
          return ({
            '&.MuiTooltip-tooltip': {
              '&.MuiTooltip-tooltipPlacementBottom': {
                marginTop: '2px',
              },
              '&.MuiTooltip-tooltipPlacementTop': {
                marginBottom: '2px',
              },
              '&.MuiTooltip-tooltipPlacementLeft': {
                marginRight: '2px',
              },
              '&.MuiTooltip-tooltipPlacementRight': {
                marginLeft: '2px',
              },
            },
          });
        },
      },
    },
  },
  
});

export default darkTheme;


