import React, { useState, useRef, Fragment, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "js-cookie";
import UserModal from "./UserModal";
import { useRouter } from "next/router";
import darkTheme from '../../src/theme';
import Image from "next/image";
import CloseIconNew from "@mui/icons-material/Close";
import SignInImage from "../../public/images/SignInImage.png";
import EditIcon from '@mui/icons-material/Edit';
// import { MuiOtpInput } from 'mui-one-time-password-input'
import SignInModal from '../modals/SignInModal'
import { MuiOtpInput } from "mui-one-time-password-input";
import { TextField } from "@mui/material";

const signInContainer = {
  padding: 0,
  background: `rgba(0,0,0,0.75)`,
  '& h4': {
    fontSize: 30,
    fontWeight: 700,
    color: darkTheme.palette.primary.title,
    marginBottom: darkTheme.spacing(3),
    [darkTheme.breakpoints.down("sm")]: {
      marginTop: darkTheme.spacing(2),
      marginBottom: darkTheme.spacing(1),
      fontSize: 22,
      fontWeight: 500,
    },
  },
  '& h5': {
    fontSize: 16,
    color: darkTheme.palette.primary.location,
    fontWeight: 300,
    [darkTheme.breakpoints.down("sm")]: {
      marginBottom: darkTheme.spacing(1),
      fontSize: 14,
      fontWeight: 100,
    },
  },
  '& h6': {
    fontSize: 12,
    color: darkTheme.palette.primary.dates,
    fontWeight: 200,
  },
};

const loginCardContent = {
  backgroundColor: darkTheme.palette.background.alternate,
  opacity: 1,
  border: `1px solid ${darkTheme.palette.background.ticket}`,  
  borderRadius: 3,   
  width: '55%',
  minHeight: '60vh',
  display: 'block',
  marginTop: darkTheme.spacing(10),
  padding: darkTheme.spacing(3),
  [darkTheme.breakpoints.down("md")]: {
    width: '75%',
    padding: darkTheme.spacing(5),
    marginTop: darkTheme.spacing(3),
  },
  [darkTheme.breakpoints.down("sm")]: {
    marginTop: darkTheme.spacing(4),
    width: '95%',
    padding: darkTheme.spacing(3),
    
  },
};

function LoginOTP(props) {   
  const Router = useRouter();
  let otpInputElement = useRef();
  const { open, onClose, mobile } = props;    
  const [modalOTP, setModalOTP] = useState(false);
  const [otp, setOtp] = React.useState('')
  const [userId, setUserId] = useState(0);
  const [userModal, setUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enableResend, setEnableResend] = useState(false);
  const [hideResendBtn, setHideResendBtn] = useState(false);
  const [modalType, setModalType] = useState('otp');
  const [timer, setTimer] = useState(60);

  const onCloseOTPHandler = () => {       
    onClose();
    setLoading(false)
  }

  const onCloseUserHandler = () => {       
    setUserModal(false)
    setLoading(false)
  } 

  const handleChange = (newValue) => {
    setOtp(newValue)
    // alert(otp)
  }

  const onValidateOTP = (event) => {
    event.preventDefault();
    // let otp = otpInputElement.current?.value;    
    if (otp == "" || otp.length != 4) {      
      toast.error(`Please Enter Valid OTP`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setLoading(false);
    } else {
      axios.get(`/api/auth/msg91/verify?otp=${otp}&mobile=${mobile}`)
        .then((response) => {          
        if (response.data.type == 'success') {
          setLoading(true);
          axios.post(`/api/auth/validateMobile`, { mobile }).then((response2) => {
            if (response2.status == 200) {              
              if (response2.data.status == 1) {                
                let new_user = response2.data.user ? response2.data.user[0] : null;
                cookie.set("token", response2.data.token);
                cookie.set("user", JSON.stringify(new_user));
                // setLoggedInUser(new_user)
                setLoading(false);                
                onClose();   
                Router.reload();
              } else if (response2.data.status == 2) {               
                let user_id = response2.data.user_id ? response2.data.user_id : 0;
                setUserId(user_id);
                onClose();                 
                setUserModal(true);
              } else {
                toast.error(`Something Went Wrong, Please Try again`, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                setLoading(false);
              }
            }
          });
        } else {
          if (response.data.message == 'already_verified') {
            window.location.reload()
            setLoading(false);
          } else {
            toast.error(response.data.message ? response.data.message : `Please Enter Valid OTP`, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            setLoading(false);
          }
        }
      }).catch(err => {
        console.log(err);
      })
    }
  };

  useEffect(() => {
    (timer > 0 ? setTimeout(() => setTimer(timer - 1), 1000) : setEnableResend(true));
  }, [timer])
 
  const resendOTPHandler = (e) => {
    e.preventDefault();
    setTimer(0);
    setEnableResend(false);
    setHideResendBtn(true);
    setTimer(60);
    axios.get(`/api/auth/msg91/otp?mobile=${mobile}`).then((response) => {        
      if(response.data.type == 'success'){       
      } else {
        toast.error(`Please Try Again`, {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setLoading(false);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  const toggleModal = () => {
    setModalType('signIn')
    setOtp("");
    setModalOTP(true)
    onClose();
  }

  return (
    <Fragment>      
      <UserModal openUser={userModal} userId={userId} onCloseUser={onCloseUserHandler} />
      {modalType == 'otp' ? (
        <Typography component="div">
          <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              ...signInContainer,
              backdropFilter: "blur(5px)",
            }}
          >
            <DialogContent>
              <Box maxWidt={"xl"} component="div">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  maxWidth="xl"
                >
                  <Typography component="div" sx={loginCardContent} >
                        <Typography component="div" sx={{ width: '100%', textAlign: 'right', mr:-2 }}>
                          <CloseIconNew sx={{ fontSize: 16, cursor: 'pointer'}} onClick={onClose} />
                        </Typography>
                      <Grid container spacing={1} >
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="desktopMode">
                        <Typography component="div"
                          sx={{
                            background: '#151414',
                            borderRadius: 10,
                            p: 2,
                            mb:2
                          }}>
                          <Image src={SignInImage} alt="..." width={100} height={120} layout="responsive" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center">
                        <Typography component="div" sx={{width:{md:'90%'}, ml:{md:3}}}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignSelf="center" className="mobileMode">
                            <Typography component="div"
                              sx={{
                                background: '#151414',
                                borderRadius: 6,
                                p: 1,    
                                display: 'table',
                                margin:'0 auto'
                              }}>
                              <Image src={SignInImage} alt="..." width={120} height={120} align="center" />
                            </Typography>
                          </Grid>
                          <Typography variant="h4" sx={{ mb: darkTheme.spacing(4) }}>
                            Confirm OTP
                          </Typography>
                          <Stack display="flex" justifyContent="space-between" flexDirection="row" sx={{mt:{md:darkTheme.spacing(5), sm:darkTheme.spacing(2), xs:darkTheme.spacing(2)}, mb:darkTheme.spacing(3)}}>
                            <Typography variant="h5">
                              OTP has been sent to <br />+{mobile}
                            </Typography>
                            <Typography component="div"
                              sx={{
                                background: darkTheme.palette.background.default,
                                padding: '10px',
                                width: 40,
                                height: 40,
                                borderRadius: 10,
                                mr: 1,
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={toggleModal}
                            >
                              <EditIcon sx={{ fontSize: 16 }} />
                            </Typography>
                          </Stack>
                          <form onSubmit={onValidateOTP}>
                            <Typography component="div">
                              <Box className="temporary_input_textfield">
                                <TextField 
                                  value={otp} 
                                  onChange={(e) => e.target.value.length < 5 && handleChange(e.target.value)} 
                                  type="number" 
                                  sx={{letterSpacing: '20px', textAlign: 'center', width: '100%' }}
                                />
                              </Box>
                              {/* <MuiOtpInput value={otp} onChange={handleChange} length={4}
                                TextFieldsProps={{ size: 'small', placeholder: '' }}
                                className="reactOtp"
                              /> */}
                            </Typography>
                            {/* <input
                            type="number"
                            className="custom-form-control"
                            ref={otpInputElement}
                            placeholder="Enter OTP"
                            name="otp"
                            autoFocus={true}                          
                          /> */}
                            {!hideResendBtn ? (
                              <Typography component="div" sx={{
                                width: '100%',
                                my: darkTheme.spacing(3),                               
                              }}>
                                {enableResend ? (
                                  <Stack direction="row" justifyContent="center">
                                    <Typography variant="h6" onClick={resendOTPHandler} sx={{ cursor: 'pointer', color: darkTheme.palette.primary.main }}>Resend OTP</Typography>
                                  </Stack>
                                ) : (
                                  <Stack direction="row" justifyContent="center">
                                    <Typography variant="h6" >Resend in&nbsp; <Typography variant="span" sx={{ color: darkTheme.palette.primary.main }}>{timer}&nbsp;sec</Typography></Typography>
                                  </Stack>
                                )}
                        
                              </Typography>
                            ) : (<Typography component="div" sx={{
                                width: '100%',
                                my: darkTheme.spacing(2.5),                               
                              }}>&nbsp;</Typography>)}
                            <Stack direction="row" justifyContent="center" mt={darkTheme.spacing(2)}>
                              {otp.length < 4 ? (
                                <LoadingButton                                
                                  variant="contained"
                                  size="large"                                                                
                                  disabled="disabled"
                                  sx={{background:darkTheme.palette.grey[100], py:2, px:4, borderRadius:10}}
                                >
                                  Validate OTP
                                </LoadingButton>
                              ) : (
                                  <LoadingButton
                                    className="themeBtn"
                                    variant="contained"
                                    size="large"
                                    loading={loading}
                                    onClick={onValidateOTP}
                                    loadingPosition="end"
                                    sx={{py:2, px:4, borderRadius:10}}
                                  >
                                    Validate OTP
                                  </LoadingButton>
                              )}
                            </Stack>
                          </form>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Stack>
              </Box>
            </DialogContent>
          </Dialog>
        </Typography>
      ) : (
        <SignInModal open={modalOTP} onClose={onCloseOTPHandler} />
      )}
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
    </Fragment>
  );
}

export default LoginOTP;
