import React, { useState, useEffect, Fragment } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import List from "@mui/material/List";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import darkTheme from "../../src/theme"
import axios from "axios";
import SignInModal from "../modals/SignInModal";
import MobileNavMenu from './MobileNavMenu'
import Head from "next/head";
import {server} from '../../config'
import Tooltip from "@mui/material/Tooltip";

const headerContainer = {
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: 2,
  '& .mobileMode':{
    display:'none !important'
  },
  [darkTheme.breakpoints.down("md")]: {
    '& .mobileMode':{
      display:'block !important'
    }
  },
};

const logoContainer = {
  padding: 1,
  display: "flex",
  alignSelf: "center",
};

const menuContainerList = {
  "& ul": {
    listStyle: "none",
    alignItems: "baseline",
    display: "flex",
    "& li": {
      display: "inline-flex",
      background: "transparent",
      margin: "auto 30px",
      // borderBottom:'4px solid #efefef',
      "&:last-child": {
        margin: "auto 0px auto 30px",
      },
      "& img": {
        maxWidth: "20px",
        minWidth: "20px",
      },
      "& a": {
        color: darkTheme.palette.grey[200],
        fontSize: 14,
        letterSpacing: 0.75,        
        position:'relative',
        '&:after':{
          content: `""`,
          position:'absolute',
          backgroundColor: darkTheme.palette.primary.main,
          height:2.5,
          width:0,
          left:0,
          bottom:-7,
          // pb:0.35,
          transition: `0.5s`,
        },
        '&:hover':{
          '&:after':{
            width:`100%`,
          }
        }
      },
      "& :hover": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
        
      },
      "& :active": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
      },
      "& .active": {
        color: darkTheme.palette.primary.main,
        cursor: "pointer",
        background: "transparent",
      },
    },
  },
};

const profileDropdown = {
  background: darkTheme.palette.background.alternate,
  minWidth: "200px",
  "& ul": {
    paddingLeft: 0,
    color: `${darkTheme.palette.common.white} !important`,
    "& a": {
      color: `${darkTheme.palette.common.white} !important`,
    },
  },
};

const menuContainer = {
  [darkTheme.breakpoints.down("md")]: {
    display: "none",
  },
};

const Navbar = () => {
  const [bgClass, setBgClass] = useState("");
  const [cityName, setCityName] = useState("");
  const [loggedInUser, setLoggedInUser] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const cookieUser = parseCookies();
  const cookieCity = parseCookies();
  const { asPath, pathname } = useRouter();
  const [cities, setCities] = useState([]);
  const [open, setOpen] = useState(false);

  const router = useRouter();

  useEffect(() => {
    axios
      .get("/api/cities/list", {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setCities(response.data ? response.data.result : []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const city = cookieCity.city ? cookieCity.city : "";
    Cookies.set("city", city, {
      expiredIn: "5h",
      secure: true,
      sameSite: "none",
    });
    setCityName(city);
  }, []);

  useEffect(() => {
    const new_user = cookieUser.user ? JSON.parse(cookieUser.user) : "";
    if (new_user) {
      setLoggedInUser(new_user);
    }
    const city = cookieCity.city ? cookieCity.city : "";
    if (city) {
      setCityName(city);
    } else {
      setCityName("");
    }
    // alert(city)
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  

  const listenScrollEvent = (e) => {
    if (window.scrollY > 30) {
      setBgClass("bg-dark");
    } else {
      setBgClass("");
    }
  };
  const handleModelOpen = () => {
    setOpen(true);
  };

  const handleModelClose = () => {
    setOpen(false);
  };

  const logOutUser = (e) => {
    e.preventDefault();
    Cookies.remove("user");
    Cookies.remove("token");
    setLoggedInUser();

    if (cityName) {
      router.push("/" + cityName + "/home", null, { shallow: false });
    } else {
      router.push("/home", null, { shallow: false });
    }
  };

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  let closeMenu = () => {
    setMenuOpen(false);
  };

 

  return (
    <Fragment>
      <Typography component="div" sx={headerContainer}>
        <Typography component="div" className={bgClass}>
          <Box>
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            > 
              <Link
                href={"/"}
                as={"/"}
                sx={{ flexGrow: "2" }}
              >
                <a>
                  <Typography component="div" sx={logoContainer}>
                    <Image
                      src="/images/new-logo.png"
                      alt="clubr-logo"
                      width={125}
                      height={40}
                      layout="fixed"
                      priority
                      sx={{ p: 1 }}
                    />
                  </Typography>
                </a>
              </Link>
              <Typography component="div" sx={menuContainer}>
                <Typography component="div" sx={menuContainerList}>
                  <Typography component="ul">
                  <li>
                      <Link href={cityName == "" ? "/all-events" : "/" + cityName + "/all-events"}>
                        <a
                          className={
                            asPath.includes("/" + cityName + "/all-events")
                              ? "active"
                              : "" || asPath.includes("/all-events")
                              ? "active"
                              : ""
                          }
                        >
                          Events
                        </a>
                      </Link>
                    </li>
                    
                    <li>
                      <Link href={"/backstage"}>
                        <a
                          className={
                            asPath.includes("/backstage")
                              ? "active"
                              : ""
                          }
                        >
                          Backstage
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/link"}>
                        <a
                          className={
                            asPath.includes("/link")
                              ? "active"
                              : ""
                          }
                        >
                          Link
                        </a>
                      </Link>
                    </li>
                    {/* <li>
                      <Link href={"https://tribe.clubr.in"}>
                        <a
                          className={
                            asPath.includes("/tribe")
                              ? "active"
                              : ""
                          }
                        >
                          Tribe
                        </a>
                      </Link>
                    </li> */}
                    <li>
                      <Link href={"/careers"}>
                        <a
                          className={
                            asPath.includes("/careers")
                              ? "active"
                              : ""
                          }
                        >
                          Careers
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/mag"}>
                        <a
                          className={
                            asPath.includes("/mag")
                              ? "active"
                              : ""
                          }
                        >
                          Mag
                        </a>
                      </Link>
                    </li>
                    {loggedInUser ? (
                      <>
                        <li
                          // onClick={recordButtonPosition}
                          onMouseOver={recordButtonPosition}
                          onMouseOut={closeMenu}
                          // passHref
                        >
                          <Tooltip title="Account settings">
                            <a>Profile</a>
                          </Tooltip>
                          <Menu
                            id="account-menu"
                            anchorEl={anchorEl}
                            hideBackdrop={false}
                            open={menuOpen}
                            onClose={closeMenu}
                            onMouseOut={closeMenu}
                            // keepMounted
                            onMouseLeave={closeMenu}
                            PaperProps={{
                              elevation: 1,
                              sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,1))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: darkTheme.palette.background.alternate,
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Typography component="div" sx={profileDropdown}>
                              <MenuItem>
                                <List href="/" passHref>
                                  <a
                                    href="/profile"
                                    style={{
                                      color: darkTheme.palette.primary.main,
                                    }}
                                  >
                                    Profile{" "}
                                  </a>
                                </List>
                              </MenuItem>
                              <MenuItem>
                                <List href="/" passHref>
                                  <a
                                    href="/bookings"
                                    style={{
                                      color: darkTheme.palette.primary.main,
                                    }}
                                  >
                                    Bookings{" "}
                                  </a>
                                </List>
                              </MenuItem>
                              <MenuItem>
                                <List href="/" passHref>
                                  <a
                                    href="/"
                                    onClick={logOutUser}
                                    style={{
                                      color: darkTheme.palette.primary.main,
                                    }}
                                  >
                                    Logout
                                  </a>
                                </List>
                              </MenuItem>
                            </Typography>
                          </Menu>
                        </li>
                      </>
                    ) : (
                      <li onClick={handleModelOpen}>
                        <a>Sign In</a>
                      </li>
                    )}
                  
                  </Typography>
                </Typography>
              </Typography>
              <Typography component="div" className="mobileMode">
                <MobileNavMenu />
              </Typography>
            </Container>
          </Box>
        </Typography>
        <SignInModal
            open={open}
            onClose={handleModelClose}
            disableCancelButton={false}
          />
      </Typography>
    </Fragment>
  );
};
export default Navbar;
