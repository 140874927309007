import React, {Fragment, useState, useEffect} from 'react';
import Hamburger from 'hamburger-react'
import { Typography, Drawer } from '@mui/material';
import Link from 'next/link';
import darkTheme from "../../src/theme";
import { parseCookies } from "nookies";
import SignInModal from "../modals/SignInModal";
import Stack from '@mui/material/Stack'
import FacebookIcon from '@mui/icons-material/FacebookTwoTone';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function MobileNavMenu() {
  const cookieUser = parseCookies();
  const cookieCity = parseCookies();
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [cityName, setCityName] = useState("");

  const muiDrawer = {  
    '& .MuiDrawer-paper': {
      background: '#61FC6E',
      borderLeft:`1px solid #red`,
      maxWidth: '90%',
      width: '90%',
      borderRadius:'20px 0 0 20px',
      // paddingBottom: 12,
      [darkTheme.breakpoints.down("md")]: {
        maxWidth: '90%',
        width: '90%',
      },
    }
  };
  
  const HamburgerStyle = {
    zIndex:1,
    '& .hamburger-react':{
      mt:2,
      borderRadius:50,
      color: darkTheme.palette.primary.main,
      zIndex:1,
      '& div':{
        height:`2px !important`,
      },
      '& div:nth-child(1)': {
        top:`12px !important`,
      },
      '& div:nth-child(2)': {
        top:`22px !important`,
      },
      '& div:nth-child(3)': {
        top:`32px !important`,
      }
    }
  }
  const menuContainer = {
    zIndex:0,
    height:'100vh',
    mt:12,
    ml:6,
    display:'flex',
    alignItems:'start',
    flexDirection:'column',
    justifyContent:'flex-start',
    textAlign:'left',
    '& a':{
      color:'#000',
      fontSize:18,
      fontWeight:700,
      mb:4,
      display:'block',
      textAlign:'left',
      transition:'0.5s',
      textTransform:'uppercase',
      '&:hover':{
        color:'#000',
        transition:'0.5s',
      }
    },
    '& .active':{
      transition:`0.75s` ,
      color:'#000',
    },
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const new_user = cookieUser.user ? JSON.parse(cookieUser.user) : "";
    if (new_user) {
      setLoggedInUser(new_user);
    }

    const city = cookieCity.city ? cookieCity.city : "";
    if (city) {
      setCityName(city);
    } else {
      setCityName("");
    }
  }, []);


  


  const handleModelOpen = () => {
    setOpen(true);
  };

  const handleModelClose = () => {
    setOpen(false);
  };


  return (
    <Fragment>
      <Typography component="div" sx={HamburgerStyle}>
        <Hamburger toggled={isOpen} toggle={setIsOpen} />
      </Typography>
  
      < Drawer
        anchor="right"
        open={isOpen}
        onClose={() => null}
        ModalProps={{
            keepMounted: false,
        }}
        hideBackdrop={false}         
        sx={{
            ...muiDrawer,
            backdropFilter: "blur(5px)",
        }}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
      >
        {isOpen && (
          <Typography component="div" 
          sx={{
            right:12, 
            top:12, 
            p:0,
            position:'absolute !important',
            background:'#000',
            borderRadius:50,
            transform:`scale(0.8)`
            }}
          >
            <Hamburger toggled={isOpen} toggle={setIsOpen} />
          </Typography>
        )}

        <Typography component="div" sx={menuContainer}>
          <Link href={cityName == "" ? "/all-events" : "/" + cityName + "/all-events"}><a>Events</a></Link>
          <Link href='/backstage'><a>Backstage</a></Link>
          <Link href='/link'><a>Link</a></Link>
          {/* <Link href='https://tribe.clubr.in'><a>Tribe</a></Link> */}
          <Link href='/careers'><a>Careers</a></Link>
          <Link href='/mag'><a>Mag</a></Link>
          {loggedInUser ? (
            <Link href='/profile'>Profile</Link>
          ):(
            <Typography component="p" onClick={handleModelOpen} sx={{color:'#000', textTransform:'uppercase', fontSize:17, fontWeight:700, cursor:'pointer'}}>Sign In</Typography>
          )}
          </Typography>
          <Typography sx={{px:6, py:3, borderTop:`0.5px solid #00000044`}}>
            <Stack flexDirection="row" justifyContent="flex-start"> 
                <Link href="https://www.facebook.com/Clubrapp" target="_blank"  rel="noreferrer"> 
                  <FacebookIcon sx={{color:'#000', fontSize:25,mr:5}} />
                </Link>
                <Link href="https://twitter.com/Clubr14" target="_blank"  rel="noreferrer">
                  <TwitterIcon sx={{color:'#000', fontSize:25,mr:5}} />
                </Link>
                <Link href="https://www.instagram.com/clubr.in/" target="_blank"  rel="noreferrer"> 
                  <InstagramIcon sx={{color:'#000', fontSize:25,mr:5}} />                          
                </Link>
                <Link href="https://www.linkedin.com/company/clubrapp" target="_blank"  rel="noreferrer">
                  <LinkedInIcon sx={{color:'#000', fontSize:25}} />
                </Link>
            </Stack>
          </Typography>
      </Drawer>
      <SignInModal
        open={open}
        onClose={handleModelClose}
        disableCancelButton={false}
      />
    </Fragment>
  )
}

export default MobileNavMenu